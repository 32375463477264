.equinoxLogo,.equinoxLogo2{
  display: flex;
  justify-content: space-between;
  /* height: 5vh; */
}

.equinoxLogo>img{
  width: auto;
height: 70px;
flex-shrink: 0;
}

#footer_container{
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding-left: 5em;
  padding-right: 1em;
  /* border-top:1px solid #DDDD; */
  padding-top: 10px;
  /* background-color: white; */
}

.equinoxLogo2>img, .customerhubLogo>img{
  width: auto;
height: 70px;
flex-shrink: 0;
}

.customerhubLogo{
  display: flex !important; 
  justify-content: end !important;
}
#container{
    width: 100%;
    max-width:1500px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;  
}

#footer_container{
    width: 100%;
    max-width:1500px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;  
}
#no_data_icon{
  height:45vh;
}

#top_bar{
    width: 100%;
    z-index: 10;
    height: 50px;
    line-height: 50px;
    position: relative;
    background-color: #3992d5;
}

#top_bar_contact_item{
    font-size: 14px;
    margin-right: 30px;
    position: relative;
    line-height: 51px;
    color: rgba(255, 255, 255, .9);
}

#top_bar_contact_item > a{
  font-family:  Roboto, sans-serif ;
  font-weight: 400;
  font-size: 14px;
  color: white;
  text-decoration: none;
}

#top_bar_icons{
    display: inline-block;
    /* vertical-align: middle; */
    margin-right: 8px;
}

#footerCopyright{
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
}

#social_icons li{
    display: inline-block;
    border: none;
    z-index: 1;
    position: relative;
    margin: 0 5px;
/* } */

/* #top_bar #social_icons li{ */

    min-width: 20px;
    margin-left: 0;
    font-size: 14px;

}

/* .ml-auto{
  margin-left: auto !important;
} */
#login_pic{
  height: 79vh;
}
#headStyle1{
  /* // marginTop: "10vh", */
  font-size: 48px;
  font-weight: 800;
  font-family:Roboto;
  color: #44546A;
  word-wrap:break-word;
}

#toggle_contain>button{
  font-size: 12px;
  padding: 5px;
  text-transform: capitalize 
}

#loginCard{
/* width: 30vw; */
width: 555px;
margin-left: auto;
border: 2px solid white;
border-radius: 25px;
/* height: 70vh; */
height: 400px;
padding: 2vh;
color: #44546A;
position: relative;
box-shadow: rgba(92, 91, 91, 0.35) 0px 5px 15px;
/* display: flex;
flex-direction: column;
justify-content: right; */
}

.welcome_header{
/* margin-bottom:7vh; */
margin-top: 3vh;
/* font-weight:700; */
font-family:Roboto;
font-size:18px;
word-wrap:break-word;
}

.register_msg{
font-size: 12px;
 margin: auto;
 max-width: 25vw;   
color: #44546A;
font-family: Roboto;
/* font-weight: 600; */
word-wrap: break-word;
margin-top: 8px;
}

.otpLabel{
font-weight: 500;
font-size: 16px;
color: rgb(0, 124, 195);
font-family: Roboto;
margin-bottom: 5px;
margin-top: 30px;
/* display: flex; */
text-align: left;
margin-left: 2.8vw;
}
.number_inp{
  margin-top: 20px !important;
}
.code_inp{
  margin-top: 10px !important;
}
#loginCard>div>h5{
color: #44546A;
font-family: Roboto;
font-size: 19px;
font-style: normal;
/* font-weight: 700; */
line-height: normal;
}
#loginCard>div>p{
color: #858484; 
text-align: center;
font-family: Roboto;
font-size: 11px;
font-style: normal;
/* font-weight: 600; */
line-height: normal;
}
.cardFooter{
  /* display: flex;
  font-size: 12px;
  position: absolute;
  bottom: 0; */
   bottom: 12px;
    left: 0;
    display: flex;
    font-size: 12px;
    position: absolute;
}
.cardFooter>p{
color: #44546A;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
#login_header{
color: #44546A;
font-family: Roboto;
font-size: 48px;
font-style: normal;
font-weight: 800;
line-height: normal;
}

#login_btn{
width: 285px;
height: 40px;
flex-shrink: 0;
border-radius: 24px;
background: #007CC3;
}

/* Account page css */

#header_msg{
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 50px;
width: 713px;
color: #44546A;
margin-left: auto;
margin-right: auto;
/* text-align: center; */
}

.equinoxLogo >h4{
font-family: Roboto;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: normal;

}

#copyright{
color: #5F6368;
font-family: Roboto, sans-serif;
font-size: 14px;
line-height: 24px;
font-weight: 400;
line-height: normal;
margin-bottom:0 !important;
}

#site_list_container{
  display: flex;
  justify-content: center;
}
#site_list_container>div{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0px 20px 30px 20px;
  max-height: 500px;
  overflow-y: auto;
  /* padding: 30px 20px; */
}

#site_header{
  font-size: 14px;
  margin-bottom: 20px;
}

#name_contain {
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
  /* background-color: white; */
  cursor: pointer;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 10px;
  color: #44546A;
  /* text-decoration: underline; */
  border-bottom: 1px solid #D9D9D9;
  box-shadow: -5px 4px 4px -5px #D8D8D8;
  text-align: start;
}
#name_contain > h2 {
font-size: 16px;
margin-top: 5px;
/* font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal; */
}

#name_contain span{
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
/* #name_contain:hover {
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
background-color: #007cc385;
color: #444a4f;
transition: 300ms;
} */

/* HEAT MAP */


.legendUnit{
height: 15px;
width: 15px;
background-color: #007cc3;
/* border: 1px solid black; */
}
.monthTitle{
font-size: 12px;
color: #44546A;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.legendDiv{
display: flex;
gap: 10px;
align-items: center;
justify-content: flex-start;
}
#heatMapContainer{
height: 20vh;
width: 110vh;
border: 1px solid black;
margin-top: 4vh;
display: flex;
flex-direction: column;
} 
.unitDiv{
display: flex;
flex-direction: column;
align-items: center;
}
.singleDiv{
display: flex;
flex-direction: column;
align-items: center;
width: 30px;
flex-direction: column;
}
.unit{
background-color: #007cc3;
width: 37.176px;
height: 37.176px;
margin-top: 5px;
border-radius: 2.558px;
border: 0.512px solid #44546A;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.unit1{
background-color: white;
width: 37.176px;
height: 37.176px;
margin-top: 5px;
border-radius: 2.558px;
border: 0.512px solid #44546A;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
display: flex;
justify-content: center;
align-items: center;
}

.unit2{
background-color: #007CC31A;
width: 37.176px;
height: 37.176px;
margin-top: 5px;
border-radius: 2.558px;
border: 0.512px solid #44546A;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
display: flex;
justify-content: center;
align-items: center;
}
.unit3{
background-color:  rgba(0, 124, 195, 0.50);
width: 37.176px;
height: 37.176px;
margin-top: 5px;
border-radius: 2.558px;
border: 0.512px solid #44546A;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
display: flex;
justify-content: center;
align-items: center;
}


.serviceName{
display: flex;
align-items: flex-end;
flex-direction: row;
justify-content: center;
width: 15%;

}
.serviceName>p{
color: #44546A;
font-size: 12px;
font-style: normal;
/* font-weight: 700; */
line-height: normal;
font-family: Roboto;
}

#headStyle1{
  margin-top: 3vh;
 font-size: 30px !important;
 font-weight: 800;
 font-family:Roboto;
 color: #44546A;
 word-wrap:break-word;
}
#header_msg{
font-size: 18px;
}

#loginCard{
width: 60vh;
border-radius: 25px;
min-height: 50vh;
max-height: 62vh;
padding: 2vh;

}

#name_contain span{
font-size: 16px;
}

.equinoxLogo >h4{
font-size: 26px;

}
.welcome_header{
/* margin-bottom:7vh; */
margin-top: 3vh;
/* font-weight:700; */
font-family:Roboto;
font-size:18px;
word-wrap:break-word;

}
.register_msg{
font-size: 12px;
/* margin: auto; */
width: 35vw !important;   
color: #44546A;
font-family: Roboto;
/* font-weight: 600; */
word-wrap: break-word;
margin-top: 8px;
}

/* Header Css */

#logo_img{
height:35px;
}

#main_header{
background: transparent !important;
}

#btn_container {
  display: flex;
  gap: 20px;
  /* margin-top: 20px; */
  justify-content: end;
}
#btn_container > button {
  display: flex;
  width: 129px;
  min-height: 30px;
  height: auto;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #007cc3;
  background-color: #fff;
  border: 1px solid #007cc3;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.5px;
}

#middleLine{
  /* color:black;
  font-size: 56px;
  position: fixed;
  right: 10.1%;
  top: -1%; */
  border-left: 2px solid black;
    height: 40px;
    margin-top: 10px;
}

/* User Site List */

#all_sites_opt{
  /* border: 1px solid #007CC3; */
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}
#all_sites_opt:hover{
  color: #007CC3;
}

/* service plans */
.menu_container{
  max-width: 500px ;
  max-height: 500px;
}

/* Employee Login */

#filter_container>form{
  display: flex;
  justify-content: center;
  gap:10px;
  margin-bottom: 20px;
}

#filter_container>form>input{
  width: 300px;
}



/* .menuContainer{
background-color: grey !important;
opacity: 0.6;
} */

/* Responsive code */

#contact_contain{
  margin-top: 27vh;
}

@media only screen and (width:1680px){

  #top_bar_contact_item{
    line-height: 51px;
}

  #middleLine{
    /* color:black;
    font-size: 56px;
    position: fixed;
    right: 11.6%;
    top: -1%; */
    border-left: 2px solid black;
    height: 40px;
    margin-top: 10px;
  }

  #headStyle1{
       margin-top: 5vh;
      font-size: 40px !important;
      font-weight: 800;
      font-family:Roboto;
      color: #44546A;
      word-wrap:break-word;
  }
  .welcome_header>h5{
    font-size: 22px !important;
  }
  #header_msg{
    font-size: 18px;
  }
  
  #loginCard{
    width: 500px;
    min-height: 400px;
    max-height: 520px;
    padding: 2vh;
  
  }
  
  #name_contain span{
    font-size: 16px;
  }

  .cardFooter>p{
    width: 460px !important;
    }
  
  .equinoxLogo >h4{
  font-size: 26px;
  
  }
  .welcome_header{
    /* margin-bottom:7vh; */
    margin-top: 3vh;
  
    font-family:Roboto;
    font-size:18px;
    word-wrap:break-word;
   
  }
  .register_msg{
    font-size: 12px;
     width: 25vw !important;   
    color: #44546A;
    font-family: Roboto;
    margin-top: 8px;
    word-wrap: break-word
  }

  #social_icons li{
    margin: 0 3px;
}

  }


@media only screen and (width:1366px){

  #top_bar_contact_item{
      line-height: 41px;
  }
  #contact_contain{
    margin-top: 15vh;
  }
  #middleLine{
    border-left: 2px solid black;
    height: 40px;
    margin-top: 10px;
  }

  .equinoxLogo>img{
    width: auto;
  }

  #container{
      max-width:1170px;  
  }

  #footer_container{
    max-width:1170px;    
}

#login_pic{
  height: 400px;
}

#btn_container > button {
  width:100px;
  height: auto;
  font-size: 12px;
  font-weight: 500;
}
#btn_container {
  gap: 10px;
}
#headStyle1{
     margin-top: 3vh;
    font-size: 30px !important;
    font-weight: 800;
    font-family:Roboto;
    color: #44546A;
    word-wrap:break-word;
}
#header_msg{
  font-size: 18px;
}

#loginCard{
  width: 400px;
  border-radius: 25px;
  min-height: 400px;
  max-height: 440px;
  padding: 2vh;

}

#name_contain span{
  font-size: 16px;
}

.equinoxLogo >h4{
font-size: 26px;

}
.welcome_header{
  /* margin-bottom:7vh; */
  margin-top: 3vh;
  font-family:Roboto;
  font-size:18px;
  word-wrap:break-word;
 
}
.register_msg{
  font-size: 12px;
   width: 35vw !important;   
  color: #44546A;
  font-family: Roboto;
  margin-top: 8px;
  word-wrap: break-word
}

#top_bar{
  height: 44px;
}

#social_icons li{
  margin: 0 2px;
}

}