#main{
  max-height:50vh;
  /* max-height:100%; */
  max-width: 100%;
  overflow: auto;
  margin-top:-10px;
}
#loader{
  height: 60vh;
}
#loader_schedule{
  height: 60vh;
  width:60vh;
  margin: auto;
}
#site_name_head{
  text-align: left;
  font-weight: 500;
  margin-top: 1%;
}
.unit_contain{
  display: flex;
  gap:20px;
  align-items: center;
  /* max-width: 60%;
  overflow-x: auto; */
}
.serviceName{
width:150px;
font-size: 14px;
margin-top: 7%;
text-align: left;
}
.unitDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.unit1{
  background-color: white;
  width: 37.176px;
  height: 37.176px;
  margin-top: 5px;
  border-radius: 2.558px;
  border: 0.512px solid #44546A;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  }

  .monthTitle{
    font-size: 12px;
    /* cursor: pointer; */
    margin-bottom: 10px;
  }
 .monthTitle2{
  visibility: hidden;
  font-size: 12px;
 }
  #selectstyle{
    width: 200px;
  }

  /* footer */

#copyright{
  text-align: left;
font-size: 12px;
margin-top: 2%;
  /* margin-left: 15%; */
}
#footer_container{
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding-left: 5em;
  padding-right: 1em;
  border-top:1px solid #DDDD;
  padding-top: 10px;
}
#support_contain>div{
  display: flex;
  gap: 10px;
  justify-content: right;
  line-height: 10px;
}
#footerCopyright{
  display: flex;
}
#support_contain>div>p{
  font-size: 12px;
}
#support_contain>div>p>span{
  font-weight: 500;
}
#no_data_icon{
  height:45vh;
}
#heat_map{
  max-height: 35vh;
overflow: auto;
/* margin-top: 20px; */
}