*{
  font-family:roboto;
}
#site_contain {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    cursor: pointer;
    border: 1px solid #b2d1e2;
    margin-top: 20px;
    width: 80%;
  }
  #site_contain:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
  }
  #left_part {
    text-align: left;
  }
  #left_part > h5 {
    margin-top: 1.4%;
    color: #007cc3;
  }
  #right_part {
    display: flex;
    gap: 20px;
    justify-content: end;
  }
  #right_part > div {
    display: flex;
    margin-top: 2%;
    gap: 7px;
  }
  #right_part > div > p {
    margin-top: -2%;
  }
  #right_part > div > p:hover {
    color: #007cc3;
  }
  
  /* Modal part */
  
  #modal_container > div {
    margin-top: 10px;
    margin-left: 20px;
  }
  #modal_btn {
    margin-top: 20px;
  }
  #modal_btn2 {
    margin-top: 20px;
    margin-right: 40px;
  }
  #modal_btn:hover,#modal_btn2:hover{
    background-color: white;
    color:#007cc3;
    transition-delay: 200ms;
  }
  #email_table{
    max-height: 100px;
    overflow: scroll;
  }
  #modal_body{
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    
  }
  
  #service_contain{
    height:auto;
    max-height: 400px;
    overflow-y: auto;
  }
  #email_table {
    width: 100%;
  }
  
  #fixTableHead {
    overflow-y: auto;   
    /* height: 200px; */
  }
  #fixTableHead thead th {
    position: sticky;
    top: 0;
  }
  #service_popup{
    width:50%;
  }
  #service_body>tr>td>p{
    margin-top: 10px;
  }
  

  /* footer css*/
  
  #footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: grey;
    text-align: center;
    z-index: 999;
    background-color: white;
    height: 4.5%;
  }
  #footer p{
    font-size: 12px;
  }

  /* Query Modals */

  #desc_modal,#service_modal{
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  #desc_modal>p>span,#service_modal>p>span{
    font-weight: 500;
  }
#close_icon{
  position: absolute;
  right:0;
  cursor: pointer;
}
  #query_popup{
    width:50%;
  }
  
  #query_modal{
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  #query_modal>form>div{
    margin-top: 20px;
  }
  #query_modal>form>div>div>label{
    font-size: 15px;
    color:grey;
  
  }
  #query_modal>form>div>div>label>span{
    color:red;
  }

  #UploadFile_container{
    width: 50%;
  }

  #submit_btn_container{
    display: flex;
    justify-content: end;
  }
  #submit_btn_container>button{
    margin-right: 1.5%;
  }

  #location_contain{
    display: flex;
        gap: 20px;
        align-items: baseline;
        margin-top: -2px;
        margin-bottom: 5px;
        flex-wrap: wrap;
  }
  #location_contain>p>span{
    font-weight: 500;
  }
#service_modal{
     display: flex;
     justify-content: left;
     gap:10px;
     flex-wrap: wrap;
  }
  #tab_contain{
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    gap:10px;
    padding: 10px;
    border-radius: 7px;
    background-color: #007cc3;
    color: white;
    width: 200px;
  }
  #loader{
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  #no_data_icon{
    height:45vh;
  }

  /* confirm user status */

#confirm_txt{
  text-align: center ;
  font-family: roboto;
  font-weight: 400;
}
#loader_contain>img{
  height: 50vh;
  width:auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* add user */

#add_user_pop_up{
  width: 50%;
}

#User_body label{
  font-size: 12px;
  color: grey;
}
#User_body label>span{
  color: red;
}
#allowedFlagContainer>div{
   display: flex;
   align-items: center;
}
#site_pop_up{
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: auto;
}


  @media all and (max-width: 759px) and (min-width: 50px) {
    #modal_btn2 {
      margin-right: 0px;
      margin-top: 0px;
    }
    #service_popup{
      width:90%;
    }
    #query_popup{
      width:90%;
    }
    #submit_btn_container>button{
      margin-right:auto;
      margin-left:auto;
    }
    #UploadFile_container,#site_pop_up{
      width: 90%;
    }
  }