#count_Container {
  display: flex;
  gap: 20px;
}
#loader{
  height: 60vh;
}
#no_data_icon{
  height:45vh;
}
#loader_heatmap{
  height:60vh;
  margin-top: -10%;
}
#count_Container > div {
  /* min-width: 83px; */
  /* min-height: 93px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(132, 134, 136, 0);
  background: linear-gradient(
    119deg,
    #fff 119.7%,
    rgba(255, 255, 255, 0) 147.56%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

#count_Container > div span {
  /* font-size: 35px;
    font-weight: 500; */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #007cc3;
}
#count_Container > div p {
  /* font-size: 20px;
    font-weight: 600;
    margin-top: 20px; */
  margin-bottom: 9px !important;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 7px;
  color: #44546a;
}
#count_Container {
  flex-wrap: wrap;
}

#status_contain {
  display: flex;
  flex-direction: row;
  border: 1px solid #b8b8b8;
  /* gap: 10px; */
  position: fixed;
  width: 43%;
  background-color: #f7f7f9;
  /* margin-left: 40px; */
}
#status_contain div {
  /* border:1px solid red; */
  display: flex;
  margin-top: 2px;
}
#status_contain div > div {
  width: 17.086px;
  height: 17.086px;
}
#status_contain div > p {
  margin-left: 5px;
  text-align: center;
  font-family: Calibri;
  font-size: 11px;
  /* font-size: 10.935px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 6%;
}

/* Download Report css */
#download_header > h6 {
  color: #000;
  text-align: left;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#service_tab_contain {
  gap: 10px;
  padding-left: 1.5%;
  /* wrap:wrap; */
}

#service_tab_contain > div {
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  /* width: 10%; */
  /* max-width: 12.5%; */
  font-size: 12px;
  font-weight: 700;
}

#service_tab_contain > div div {
  background-color: red;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  padding: 12px;
}

/* PAYMENTS */
.tabsHeading {
  margin-bottom: 8px;
  color: #000;
  text-align: left;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.tabsCount {
  color: #007cc3;
  /* text-decoration: underline; */

  text-align: left;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#leftPart div p {
  margin-bottom: 0px;
}
#leftPart {
  text-align: left;
  max-height:65vh;
  overflow-y: auto;
  /* margin-left: 2vh; */
}
.leftUnit {
  /* margin: 2vh 2vh 2vh 0; */
  box-shadow: 2px 1px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 1vh;

  display: flex;
  /* width: 200px; */
  max-width: 250px;
  overflow-x: auto;
  /* padding: 5px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  cursor: pointer;
}
#amount {
  color: #44546a;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#amt_val {
  color: #007cc3;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#trans_date {
  color: #44546a;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


/* #rightPart{
  margin-left: 1vh;
} */

/* #rightPart > div > h2 {
  color: #007cc3;
  font-family: Roboto;
  font-size: 21.955px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}
#rightPart > div > h6 {
  color: #000;
  font-family: Roboto;
  font-size: 14.637px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
#rightPart > div > h6 > span {
  color: #007cc3;
  font-family: Roboto;
  font-size: 21.955px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
} */



#adjust_btn {
  display: flex;
  width: 130.815px;
  padding: 4.574px;
  justify-content: center;
  align-items: center;
  color: #fff;

  text-align: center;
  font-family: Roboto;
  font-size: 11.892px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.296px; /* 153.846% */
  letter-spacing: -0.457px;
}

#map_contain {
  /* max-height: 60vh;
  overflow: auto; */
}
#heat_map{
    max-height: 53vh;
  overflow: auto;
  /* margin-top: 20px; */
}

/* download report */
#pdf_msg{
  min-height:75vh;
  max-height: auto;
}
#pdf_contain{
  height:100%;
  width: 100%;
}

/* footer */

#copyright{
  text-align: left;
font-size: 12px;
margin-top: 2%;
  /* margin-left: 15%; */
}
#footer_container{
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding-left: 5em;
  padding-right: 1.8em;
  border-top:1px solid #DDDD;
  padding-top: 10px;
  background-color: white;
}
#support_contain>div{
  display: flex;
  gap: 10px;
  justify-content: right;
  line-height: 10px;
  /* margin-right: 20px; */
}
#footerCopyright{
  display: flex;
}
#support_contain>div>h6{
  font-size: 12px;
  margin-bottom: 0.3rem;
  color: #44546a;
  font-weight: 400;
}
#support_contain>div>p>span{
  /* font-weight: 500; */
}

#iris_logo_img{
  height: 100px;
  width: auto;
  position: absolute;
  margin-top: -50px;
  right: 30px;
  object-fit: contain;
}

@media only screen and (width:1680px){

    #count_Container > div span {
      font-size: 18px;
    }
    #count_Container > div p {
      font-size: 16px;
    }
    #btn_container > button {
      width: 110px;
      height: auto;
      font-size: 12px;
      font-weight: 500;
    }

    #support_contain>div>h6{
      margin-bottom: 0.4rem;
    }
    
  }

@media all and (max-width: 1370px) and (min-width: 700px) {

  #support_contain>div>h6{
    margin-bottom: 0.3rem;
  }

  #count_Container > div span {
    font-size: 18px;
  }
  #count_Container > div p {
    font-size: 16px;
  }
  #btn_container > button {
    width: 110px;
    height: auto;
    font-size: 12px;
    font-weight: 500;
  }
  #btn_container {
    gap: 10px;
  }


  #service_tab_contain > div {
    font-size: 10px;
    font-weight: 700;
  }
}


@media all and (max-width: 699px) and (min-width: 50px) {
  #status_contain {
    position: relative;
  }
}