
#loader_contain{
    display: flex;
    justify-content: center;
    align-items: center;
}
#loader_contain>img{
    height:60vh;
    width: auto;
    margin-top: -7vw;
}
#loader_container{
    display: flex;
    justify-content: center;
    align-items: center;
}
#loader_container>img{
    height: 50vh;
    margin-top: -7vw;
}
#audit_header{
    margin-top: -2vw;
}
#list_head{
    text-align: left;
    font-size: 18px;
    color: #4F4F4F;
}
/* #audit_header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    margin-top: 4.4vw;
    background-color: white;
    padding-top: 5px;
} */
#logo_contain{
    display: flex;
    align-items: center;
    justify-content: right;
}
#logo_contain>img{
    height:62px;
    width: 195px;
}
#btn_contain{
    gap: 10px;
    display: flex;
}
#btn_contain>button{
  width:7%;
  padding: 5px;
  border: 1px solid rgb(201, 196, 196);
  border-radius: 5px;
}
#btn_contain_section{
    gap: 10px;
    display: flex;
}
#btn_contain_section>button{
    width:18%;
    padding: 5px;
    border: 1px solid rgb(201, 196, 196);
    border-radius: 5px;
}
#site_details_container{
    height: 31vw;
    overflow-y: auto;
    overflow-x: hidden;
    background-color:white;
    /* background-color:rgb(243, 240, 240); */
}
.service_tbl{
    /* height: 64vh; */
    max-height: 64vh;
    margin-top: 20px;
}
#table_unit{
    min-width: 100px;
    padding: 5px !important;
}
#info_container{
    height:200px;
    padding:10px;
    width: 270px;
    background: rgba(51, 51, 51, 0.09);
    border-radius: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
}
#info_container>div{
    display: flex;
    align-items: center;
    gap:1vw;
    margin-top: 10px;
}
#info_container>div>div{
    height:20px;
    width:50px;
    border-radius: 30px;  
}
#info_container>div>h6{
 text-align: left;
 width: 70%;
 font-size: 12.39px;
 /* margin-top: 10px; */
}
#seven_header{
    text-align: left;
    font-size: 24px;
    font-weight: 700;
}
#overall_container{
    background-image: url("../Media/india_map.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height:31vw;
}
#overall_container{
    /* margin-top: -3vw; */
}
.service_tbl table thead th{
    background-color: white !important;
    color: black !important;
    font-size: 16px;
    font-weight: 600;
}
.service_tbl table thead,.service_tbl table {
    border: 1px solid rgb(209, 207, 207);
}
#schedule_tbl table thead th{
    padding: 5px !important;
}
.readMoreClassName,.readLessClassName{
    color: #007cc3;
    font-size: 12px;
}
#pie_contain{
    position: absolute;

    /* left: 0; */
}

#capa_status{
    background-color: #007cc3;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

#client_name{
    font-weight:500;
    text-align:right;
    margin-left:-70px;
    margin-bottom: 10px;
}

#bckbtn{
    cursor: pointer;
    border: 1px solid #007ec0;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    color: #007ec0;
    background-color: transparent;
  }
  
  #bckbtn:hover{
    -webkit-animation: bounceLeft 2s infinite;
    animation: bounceLeft 2s infinite;
  }


  @-webkit-keyframes bounceLeft {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -webkit-transform: translateX(30px);
      transform: translateX(30px);
    }
    60% {
      -webkit-transform: translateX(15px);
      transform: translateX(15px);
    }
  }
  
@-moz-keyframes bounceLeft {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(30px);
    }
    60% {
      transform: translateX(15px);
    }
  }
  @keyframes bounceLeft {
    0%,
    20%,
    50%,
    80%,
    100% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -ms-transform: translateX(30px);
      transform: translateX(30px);
    }
    60% {
      -ms-transform: translateX(15px);
      transform: translateX(15px);
    }
  }

/* .service_tbl table tbody td{
    font-size: 16px !important;
    font-weight: 400 !important;
} */

  /* Capa dashboard css */

  #capa_status_contain{
    gap: 10px;
    padding-left: 20px;
  }

  #capa_status_contain>div{
    border: 1px solid #007cc3;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }
#capa_loader_contain{
  display: flex;
  justify-content: center;
  align-items: center;
}
#capa_loader_contain>img{
  height:60vh;
  width: auto;
  margin: auto;
}

/* Scheduling new dashboard css */

#audit_countSection{
  width: 10%;
  border:0px solid rgb(199, 198, 198);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  justify-content: left;
  padding: 10px;
  padding-top: 20px;
  cursor: pointer;
  border-left: 6px solid #ccc;
  height:130px;
}
#audit_countSection>h3{
  text-align: left;
    font-size: 40px;
    font-weight: 500;
}
#audit_countSection>p{
  text-align: left;
  margin-top: 10px;
  font-size: 20px;
  color: #211d1d;
}

#capa_counSection{
  display: flex;
  gap: 10px;
  border: 0px solid rgb(199, 198, 198);
  padding: 10px;
  border-radius: 5px;
  border-left: 6px solid #ccc;
  height:130px;
}
#capa_counSection>div{
  text-align: left;
}
#capa_counSection>div p{
  font-size: 12px;
  color: #211d1d;
  cursor: pointer;
}
#capa_counSection>div h3{
  font-size: 40px;
  font-weight: 500;
}
#audit_countSection>p:hover{
  text-decoration: underline;
  color: #007cc3;
}
#capa_counSection>div p:hover{
  text-decoration: underline;
  color: #007cc3;
}
#capa_counSection>div p:hover span{
  color: #007cc3;
}
#capa_counSection>div p>span{
  color: black;
  font-weight: 500;
}

#tabs_container>div{
  cursor: pointer;
  padding: 5px;
  height: 38px;
}


#table_footer {
  left: 0;
  bottom: 0;
  z-index: 0;
  position: sticky;
  /* background-color: #89BBE0; */
  /* background-color: white; */
  background-color: #007cc3;
  /* color: #444a4f !important; */
  /* color: white !important; */
  margin-top: 10px;
  height: 30px;
}
#table_footer p {
  font-size: 13.5px;
  /* color: #444a4f;  */
  color: white;
  font-weight: 500;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 0.4rem;
}
#first_col {
  position: sticky !important;
  z-index: 9999 !important;
  /* background-color: white; */
  /* background-color: #89BBE0; */
  background-color: #007cc3;
  height: 30px;
  left: 0;
  /* width: 20%; */
}
#iris_logo_img{
  height: 100px;
  width: auto;
  position: absolute;
  margin-top: -50px;
  right: 30px;
  object-fit: contain;
}

@media all and (max-width: 1370px) and (min-width: 700px) {
    #overall_container{
        /* margin-top: -3vw; */
        height: 38vw;
    }
    #site_details_container{
        height: 38vw;
    }
    .service_tbl{
        /* height: 57vh; */
        max-height: 57vh;
        margin-top: 20px;
    }
}