#table_contain_service {
    max-height: 70vh;
  }
  .th_unit,
  .th_unit_null,
  #td_unit,.th_bottom {
    border: 1px solid rgb(201, 201, 201);
    background-color: white;
    /* padding: 0px !important; */
    /* text-align: center; */
    background-color: white !important;
    color: black;
    text-align: center !important;
    vertical-align: bottom;
   width: 300px;

    /* max-width: 300px; */
  }
  .noData {
    padding: 0px !important;
  }
#no_data_icon{
  height:45vh;
}

  .th_bottom{
    /* border-bottom: 0px; */
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .th_unit{
     width: 100%;
     margin-left: auto;
     margin-right: auto;
     display: block;
  }


  .th_unit_null {
    text-align: center !important;
  }
  #service_tbl{
    margin-top: 15px;
    height: 65vh;
    max-height: 65vh;
    /* max-height: 65vh; */
  }
  #service_tbl tbody td{
    padding: 5px 10px !important;
  }
  #th_unit1,
  #td_unit,
  #th_unit1_sales {
    border: 1px solid rgb(201, 201, 201);
    vertical-align: middle;
  }
  #td_unit > h6 {
    text-align: center;
    margin-left: 5px;
    font-weight: 400;
  }
  
  #th_unit1 > h6,
  #th_unit1_sales > h6 {
    text-align: center;
  }
  
  #th_unit1_sales {
    padding: 10px;
  }
  #footer_div {
    background-color: white;
  }
  #footer_div > div {
    display: flex;
    align-items: baseline;
    color: black !important;
  }
  .th_unit1 > h5 {
    color: white;
  }
  thead th {
    background-color: #007cc3 !important;
    color: white !important;
    padding-top: 10px !important;
    /* padding-bottom: 10px !important; */
 
  }
#th_unit1>h6{
  font-size: 14px !important;
}

#th_unit1{
  width: 300px;
}
  /* footer */

#copyright{
  text-align: left;
font-size: 12px;
margin-top: 2%;
  /* margin-left: 15%; */
}
#footer_container{
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding-left: 5em;
  padding-right: 1em;
  border-top:1px solid #DDDD;
  padding-top: 10px;
}
#support_contain>div{
  display: flex;
  gap: 10px;
  justify-content: right;
  line-height: 10px;
}
#footerCopyright{
  display: flex;
}
#support_contain>div>p{
  font-size: 12px;
}
#support_contain>div>p>span{
  font-weight: 500;
}
#loader{
  height: 60vh;
}
#header_name{
  font-size: 14px  !important;
}
#service_header {
  color: #000;
  text-align: left;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-left: 5vw;
}
#table_container table td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
}

.first_header{
  position: sticky !important;
  left: 0 !important;
  background-color: #007cc3 !important;
  z-index: 99999 !important
}

@media all and (max-width: 1370px) and (min-width: 700px) {
  #service_tbl{
    margin-top: 15px;
    height: 60vh;
    max-height: 65vh;
  }
}