#list_head {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: normal;
}
#loader{
  height: 60vh;
}
#no_data_icon{
  height:45vh;
}
th > div {
  font-size: 14px !important;
  background-color: transparent !important;
}

tr > td {
  font-size: 12px !important;
}
tr > td > div > svg {
  font-size: 1.2rem !important;
}
#purchase_header {
  display: flex;
  justify-content: left;
}
#purchase_header > div {
  text-align: left;
}
#purchase_header > div span {
  /* font-size: 35px;
    font-weight: 600; */
  color: #007cc3;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
#purchase_header > div p {
  /* font-weight: 600; */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#bills_info {
  padding: 5px 30px;
}
#bills_info > p {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#bills_info > div {
  text-align: left;
}
#bills_info > div > p {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#bills_info > div > div > p {
  /* border-bottom: 1px solid red; */
  width: 60%;
}

#copyright {
  color: #5f6368;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#site_name {
  text-align: left;
  margin-left: 20% !important;
  padding-right: 10px;
}

#mainTableDiv {
  text-align: center;
  border-radius: 5px;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  */
}

#mainTableDiv > table > thead {
  border-top: 2px solid rgba(132, 134, 136, 0.5);
  border-bottom: 2px solid rgba(132, 134, 136, 0.5);
}

#invoice_amounts {
    /* border-bottom:2px solid #007cc3; */
    border-bottom: 2px solid rgb(201, 198, 198);
    color:#007cc3;
    font-weight: bold;
}

#overdue_amounts {
    /* border-bottom:2px solid red; */
    border-bottom: 2px solid rgb(201, 198, 198);
    color:red;
    font-weight: bold;
}
#payment_drop {
  min-width: 220px;
  height: 35px;
  outline: none;
}
#city_drop {
  height: 35px;
  margin-left: 20px;
  outline: none;
  min-width: 220px;
}

/* Query Page */

#status_contain{
  margin-bottom: 40px;
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: -30px;
  margin-right: 2%;
  font-size: 14px;
}
#status_contain>div{
 min-height: 40px;
 max-height: auto;
 width: auto;
 display: flex;
 justify-content: center;
 align-items: center;
 background-color: #007cc3;
 color: white;
 border-radius: 10px;
 cursor: pointer;
 font-weight: 800;
}
#status_btn{
  border:1px solid white;
  padding: 5px 10px;
  border-radius: 7px;
}
#view_link{
  color:#007cc3;
  text-decoration: underline;
  margin-left: 7%;
  cursor: pointer;
  margin-bottom: 0px;
}
#view_btn{
  cursor: pointer;
}
#view_btn:hover{
  color:#007cc3;
}
.readMoreClassName,
.readLessClassName {
  color: #007cc3;
}


/* download report section */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown_content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 5px;
  z-index: 99999 !important;
  margin-left: -12vh;
  top:0;
  margin-top: -5vh;
}
.dropdown_content>div{
  margin-top: 5px;
}
.dropdown_content>div:hover{
  background-color: azure;
}

.dropdown:hover .dropdown_content {
  display: block;
}

#filter_label{
  color: #007cc3;
  font-weight: 400;
    font-size: 11px;
}

/* footer */

#copyright{
  text-align: left;
font-size: 12px;
margin-top: 2%;
  /* margin-left: 15%; */
}
#footer_container{
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding-left: 5em;
  padding-right: 1em;
  border-top:1px solid #DDDD;
  padding-top: 10px;
}
#support_contain>div{
  display: flex;
  gap: 10px;
  justify-content: right;
  line-height: 10px;
}
#footerCopyright{
  display: flex;
}
#support_contain>div>p{
  font-size: 12px;
}
#support_contain>div>p>span{
  font-weight: 500;
}


/* add User section */

#filter_container{
  display: inline-flex;
}

#loader_contain{
  display: flex;
  justify-content: center;
  align-items: center;
}
#loader_contain>img{
  height:60vh;
  width: auto;
}

/* Purchase  */

#iris_logo_img{
  height: 100px;
  width: auto;
  position: absolute;
  margin-top: -25px;
  right: 30px;
  object-fit: contain;
}