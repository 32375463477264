.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 0px 0 5px !important;
  /* color: #b8b8b8 !important;
  border-bottom: red !important; */
}

#site_name{
  margin-top: -10vw;
  font-size: 14px;
  /* fontWeight:"600" */
}

#client_piechart .recharts-wrapper{
  margin-bottom: -10vw !important;
}

/* equnox icon hover remover */

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover{
  background-color:white !important;
}

.css-clgvk4-MuiButtonBase-root-MuiIconButton-root:hover{
  background-color: #007CC3 !important;
}


/* Table CSS */

thead th{
  text-align: center !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: normal !important;
text-transform: capitalize !important;
padding: 3px !important;
}

/* 
thead th span{
  text-align: center !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: normal !important;
text-transform: capitalize !important;
} */

/* thead th button{
  padding: 0px !important;
} */


tbody td p {
  margin: 3% auto 3% 5% !important;
}
#yearly_tbl thead th{
  color: black !important;
  background-color: white !important;
}
#yearly_tbl tbody td p{
  margin: 0 !important;
}
#yearly_tbl tbody td{
  padding: 3px !important;
  border: 1px solid white;
}
#yearly_tbl thead h6{
  color: #000;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 16px; 
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body.swal2-toast-shown .swal2-container.swal2-top-end,
body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: 9999 !important;
}
.custom-html-container {
  line-height: 1.4 !important; /* Adjust the value as needed */
  font-weight: bold !important;
  padding: 0 !important;
}
/* .swal2-popup {
  padding: 0 !important;
} */

table > tfoot > tr > td > div > div > div {
  display: flex !important;
  align-items: baseline !important;
}
table > tfoot > tr > td {
  padding: 2px 4px !important;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar,.css-8nphli{
  display: flex !important;
  align-items: baseline !important;
}

/* thead th span div {
  justify-content: center !important;
  text-transform: capitalize !important;
}
 */

 thead th span button div{
  text-transform: capitalize !important;
display: flex !important;
justify-content: center !important;
text-align: center !important;
padding: 0px !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 600 !important;
}

tbody td {
  padding: 0px !important;
  text-align: center !important;
  font-family: Roboto !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal !important;
}
.css-1jzplss-MuiPaper-root-MuiAppBar-root,.css-s43fis{
  background-color: white !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
.css-pwxzbm {
  margin-top: 0% !important;
  height: auto !important;
  max-height: 350px !important;
  position: absolute !important;
}
.tss-ynxllk-MUIDataTableFilter-root {
  position: relative !important;
}
#collection_tbl .tss-ynxllk-MUIDataTableFilter-root {
  position: relative !important;
  width: 600px !important;
}
/* table css end */

body{
  /* background: url("../src/Media/customer-hub-background.jpg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  /* top:-20px  */
  /* position: absolute;*/
}


/* .tss-qbo1l6-MUIDataTableToolbar-actions{
  display: none !important;
} */

.css-11mde6h-MuiPaper-root{
  background-color: transparent !important;
}

.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader{
  background-color: #007cc3 !important;
}

.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader > div{
  color: white;
}

thead th span {
  justify-content: center !important;

}

thead th span div {
  justify-content: center !important;
  text-transform: capitalize !important;
  color: white !important;

}

.css-dx096b.Mui-active .MuiTableSortLabel-icon,.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon{
  color: white !important;
  margin-top: 3px !important;
}

#collection_tbl tfoot{
  z-index: 1 !important;
}
/* leads dashboard table css */

#leads_tbl table thead th{
  background-color: white !important;
  color: black !important;
}
#leads_tbl table thead th span div{
  justify-content: center !important;
  text-transform: capitalize !important;
  color: black !important;
}
#leads_tbl .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader > div{
  color: black !important;
}


/* Table CSS End */

/* graph tooltip */

.MuiChartsTooltip-root {
  max-width: 300px !important;
  padding-right: 20px;
}



/* date range picker */

.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 9999 !important;
}
.ant-picker-dropdown{
  z-index: 9999;
}
.rs-input-group.rs-input-group-inside {
  border-color: #007cc3 !important; /* Replace with your desired color */
}
/* header css */

/* sidebar */
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper,.css-1l8j5k8 {
  z-index: 999 !important;
}

/* gauge chart */

.css-b9rdri-MuiGauge-referenceArc,.css-nxtzjj{
  fill: var(--Red, #EB5757) !important;
}

/* header css end */


/* #audit_graph .css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-line{
  stroke: rgb(209, 206, 206) !important;
} */
/* #audit_graph .css-34hspi .MuiChartsAxis-line, #audit_graph .css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-line {
  stroke: rgb(209, 206, 206) !important;
  shape-rendering: crispedges !important;
  stroke-width: 1 !important;
} */

/* minidrawer */

/* .wrapper {
  border: 3px solid blue;
} */

/* .container {
  background-color: white;
  border:1px solid red
  
}

.barCompleted {
  background-color: lightblue;
}

.label {
  font-size: 20px;
  color: green;
} */


/* Scroll Bar */

::-webkit-scrollbar {
  width: 7px;
  height: 10px;

  /* background-color: #2a3140; */
}

::-webkit-scrollbar-thumb {
  background-color: #9e9692;
  border-radius: 5px;
}



/* material ui dropdown */

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,.MuiOutlinedInput-input,.css-qiwgdb{
  padding: 6.5px 14px !important;
}

/* login */
#standard-basic{
  font-size: 18px !important;
  color: #44546A !important;
  font-family: Roboto !important;
  font-weight: 400 !important;
  word-wrap: break-word !important;
}
#standard-entered{
  font-size: 18px !important;
  color: #007cc3 !important;
  font-family: Roboto !important;
  font-weight: 400 !important;
  word-wrap: break-word !important;
}

.MuiFormLabel-root{
  font-size: 12px !important;
  color: #007cc3 !important;
}

.ant-picker-panel-layout {
  min-width: 300px !important;
}
/* .css-12i7wg6-MuiPaper-root-MuiDrawer-paper,.css-19z1ozs-MuiPaper-root-MuiAppBar-root,.css-19z1ozs-MuiPaper-root-MuiAppBar-root{
  background: transparent !important;
} */

/* bar chart css */

.css-1w4jgi9-MuiResponsiveChart-container{
  /* height: 600px !important; */
  margin-top: -5vw !important;
}

/* icons css */
.css-i4bv87-MuiSvgIcon-root , .css-vubbuv{
  font-size: 1.3rem !important;
}
/* month picker css */
#month_contain .ant-space-item>div,#month_contain .ant-picker-input>div{
padding: 7px 12px 7px !important;
}

/* date range picker */

/* .rs-picker-popup.rs-picker-popup-daterange{
  z-index: 999 !important;
} */

/* side bar */

.css-tg3sd2-MuiPaper-root-MuiAppBar-root,.css-1bouosn{
  background-color: white !important;
}

/* bar graph */
@media all and (max-width: 2000px) and (min-width: 1700px) {
  #client_piechart .recharts-wrapper{
    margin-bottom: -8vw !important;
  }
}

@media all and (max-width: 1370px) and (min-width: 700px) {
  #client_piechart .recharts-wrapper{
    margin-bottom: -11vw !important;
  }
}

@media only screen and (width:1366px){
  .css-i4bv87-MuiSvgIcon-root , .css-vubbuv{
    font-size: 1rem !important;
  }
}

@media only screen and (width:1680px){
  .css-i4bv87-MuiSvgIcon-root , .css-vubbuv{
    font-size: 1rem !important;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

