#header_tab {
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
}
#selected_tab {
  cursor: pointer;
  border-bottom: 2px solid #fe688d;
  color: #fe688d;
  font-weight: bold;
  font-size: 24px;
}

#tab_container {
  /* gap:10px; */
}
#tab_container > div {
  /* border:1px solid red */
}
#tab_container > div > div > div {
  text-align: left;
}
#load_btn {
  width: 170px;
  background-color: #fe688d;
  color: white;
  padding: 10px 20px;
  margin: auto;
  border-radius: 5px;
  margin-bottom: 20px;
}
#list_head {
  text-align: left;
  font-size: 22px;
  font-weight: 400;
  color: var(--Gray-02, #878787);
  /* color: rgb(202, 202, 202); */
}
#list_head > span {
  font-size: 12px;
  color: black;
}
#icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background-color: #f3f1ff;
  /* width: 50px; */
}
#tab_icon {
  /* font-size:20px !important; */
  height: 24px !important;
  width: 21px !important;
  /* color: #1cade6; */
}
#title_tab {
  font-size: 14px;
  color: #92959e;
}
#value_tab {
  font-size: 16px;
  font-weight: 700;
}
#payment_drop,
#agreement_drop {
  min-width: 120px;
  height: 30px;
  outline: none;
  border-radius: 5px;
  border: 1px solid grey;
  /* color: #fe688d; */
  font-size: 14px;
}
#payment_drop {
  margin-left: 20px;
}
#city_drop {
  height: 30px;
  margin-left: 20px;
  outline: none;
  min-width: 220px;
  border-radius: 5px;
  border: 1px solid grey;
  /* color: #fe688d; */
  font-size: 14px;
  min-width: 120px;
}
#monthPicker {
  /* margin-left: 20px; */
  /* border: 1px solid grey; */
  border-radius: 5px;
}
#monthPicker2{
    margin-left: 20px;
  border-radius: 5px;
}
#monthPicker span {
  /* color: #fe688d !important; */
}
#progress_bar_unit > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#progress_bar_unit > div > p {
  margin-top: 0px;
  font-size: 14px;
}

#cityname {
  padding: 1px;
}
#loader {
  height: 60vh;
}
#loader_more {
  height: 60vh;
  width: 60vh;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: -5%;
}
#agreement {
  padding: 8px !important;
}

/* monthly summary csss */

#audit_tbl {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

#edit_icon {
  color: grey;
  font-size: 17px;
  background: var(--Special-BG, rgba(210, 210, 210, 0.25));
  border-radius: 4px;
}

/* #left_part > h4 { */
#left_part > div> h4 {
  font-size: 32px;
  text-align: left;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
/* #left_part > p { */
#left_part > div >p {
  text-align: left;
  font-size: 20px;
  color: var(--Gray-02, #878787);
  /* margin-top: 15px; */
  margin-top: 7px;
}
#achievement_container {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding-top: 10px;
  text-align: left;
  padding-left: 20px;
  margin-top: 10px;
/* margin : 10px 40px 0px 40px;  */

  /* position: relative; */
}
#audits {
  color: var(--Default-Black, #191919);
  /* Exbold 22-32 */

  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 145.455% */
  text-transform: capitalize;
}
#target_info > div > span {
  color: var(--Gray-02, #878787);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
#target_info > div > p {
  color: var(--Default-Black, #191919);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
}
#agreed_text{
  font-weight: 400 !important;
  margin-top: 0px ;
  color: var(--Gray-02, #878787) !important;
  font-size: 12px !important;
}
#audits_show > div > p {
  color: var(--Gray-02, #878787);

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

#audits_show > div > span {
  color: var(--Gray-02, #878787);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  cursor: pointer;
}

#audit_container {

  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 10px;
  margin-bottom: 40px;
  border-radius: 5px;
  margin-top: 20px;
  /* margin: 20px 40px 40px 40px; */
}
#header_unit {
  text-align: left;
  border-bottom: 1px solid #fe688d;
}
#tab_container {
  height: 30px;
  margin-top: 20px;
}
#tab_container > div > p {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
  cursor: pointer;
  /* color: var(--Secondary, #525256); */
}

#auditor_info,
#auditor_status {
  /* padding: 10px; */
  text-align: left;
  padding: 24px 0px;
}
#auditor_status {
  text-align: right;
  padding-right: 15px;
}
#auditor_info{
  padding-left: 20px;
}
#auditor_info > h6,
#auditor_status > h6 {
  color: var(--Secondary, #525256);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
#auditor_info > h6 {
  color: var(--Default-Black, #191919);
}
#auditor_info > p,
#auditor_status > p {
  color: var(--Gray-03, #9f9f9f);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
#audit_info_container {
  height: 300px;
  overflow: auto;
}

#other_info_container{
  height: 345px;
  overflow: auto;
}

#count_container p {
  color: #000;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
/* #status_container{
  border-right: 1px solid #fe688d !important;
} */
#status_container p {
  color: #92959e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
#status_container {
  width: 100px;
}
#status_container div {
  border-bottom: 1px solid #b7b8b9;
}
#audit_done_val {
  color: #ff698d !important;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
}

#lead_header {
  color: #92959e;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  margin-left: 10px;
}
#Statistic_header {
  color: var(--Gray-02, #878787);
  font-size: 23.993px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.899px;
  text-align: left;
}
#weekly_header {
  color: var(--Default-Black, #191919);
  font-size: 17.45px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.174px;
  text-align: left;
  /* margin-top: 15px; */
}
#date_container {
  margin-left: 52vw;
  margin-top: 7px;
}

#loader_contain {
  display: flex;
  justify-content: center;
  align-items: center;
}
#loader_contain > img {
  height: 60vh;
  width: auto;
  margin-top: -7vw;
}
#gauge_text {
  /* margin-top: -90px; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  position: absolute;
  /* bottom: 0; */
  /* margin-left: 2vw; */
  margin-left: 3.7vw;
  margin-top: -85px;
}
/* #leads_name{
  text-align: left;
  font-weight: 400;
} */

@media all and (max-width: 1400px) and (min-width: 700px) {
  #gauge_text {
    /* margin-top: -90px; */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    position: absolute;
    /* bottom: 0; */
    /* margin-left: 1.5vw; */
    margin-left: 4.5vw;
    margin-top: -85px;
  }
  #date_container {
    margin-left: 50vw;
  }
}
